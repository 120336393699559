import React from "react"
import Container from "@material-ui/core/Container"
import Link from "./link"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: "#f2545b",
    color: "#fff",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "20px",
    fontWeight: 900,
    margin: "0.3rem",
    "&:hover": {
      backgroundColor: "#a93a3f",
    },
  },
}))

const Categorie = ({ categories, prefix }) => {
  const classes = useStyles()

  return (
    <section>
      <Container>
        <h4
          style={{
            color: "#fe5f55",
            fontFamily: "Montserrat",
            fontDisplay: "swap",
          }}
        >
          Categorie
        </h4>
        <button className={classes.button}>
          <Link
            to={"/" + prefix + "/"}
            activeStyle={{
              color: "#000",
              fontFamily: "Montserrat",
              fontDisplay: "swap",
              fontSize: 20,
              fontWeight: 700,
            }}
          >
            Tutte
          </Link>
        </button>
        {categories.nodes.map((category, index) => (
          <button key={index} className={classes.button}>
            <Link
              to={"/" + prefix + "/" + category.slug}
              activeStyle={{
                color: "#000",
                fontFamily: "Montserrat",
                fontDisplay: "swap",
                fontSize: 20,
                fontWeight: 700,
              }}
            >
              {category.titolo}
            </Link>
          </button>
        ))}
      </Container>
    </section>
  )
}

export default Categorie
