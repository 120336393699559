import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import defaultImage from "../images/defaultTeam.png"
import Modal from "@material-ui/core/Modal"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Categorie from "../components/categorie"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    marginBottom: "100px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  galleria: {
    paddingTop: "65px",
    paddingBottom: "45px",
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  icon: {
    color: "white",
  },
  card: {
    width: "375px",
    marginBottom: "20px",
    transition: "0.3s",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -13.125px rgba(0,0,0,0.3)",
    },
  },
  media: {
    paddingTop: "100%",
  },
  modal: {
    justifyContent: "center",
    justifyItems: "center",
  },
  modalImage: {
    display: "block",
    margin: "auto",
    marginTop: "10vh",
    width: "auto",
    height: "70vh",
  },
  closeButtonModal: {
    position: "absolute",
    left: "2rem",
    top: "4rem",
    width: "10rem",
    backgroundColor: "#f2545b",
    fontSize: "2rem",
  },
}))

const SimpleCard = ({ title, excerpt, image }) => {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return
    setOpen(false)
  }

  return (
    <>
      <div onClick={handleOpen}>
        <Card className={classes.card} id="overlay">
          {image !== null && image !== undefined ? (
            <CardMedia
              className={classes.media}
              alt={excerpt}
              image={image}
              title={title}
              id="image"
            />
          ) : (
            <CardMedia
              className={classes.media}
              image={defaultImage}
              id="image"
            />
          )}
          <CardContent id="content">
            <h2
              style={{
                color: "#f2545b",
                fontSize: 15,
                fontWeight: "700",
                fontFamily: "Montserrat",
              }}
            >
              {title !== null ? title : ""}
            </h2>
            <p style={{ fontSize: 13 }}>{excerpt !== null ? excerpt : ""}</p>
          </CardContent>
        </Card>
      </div>
      <Modal open={open} onClose={handleClose} className={classes.modal}>
        <div>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.closeButtonModal}
          >
            Chiudi
          </Button>
          <ClickAwayListener onClickAway={handleClose}>
            {image !== null && image !== undefined ? (
              <img src={image} alt={excerpt} className={classes.modalImage} />
            ) : (
              <img
                src={defaultImage}
                alt="player avatar"
                className={classes.modal}
              />
            )}
          </ClickAwayListener>
        </div>
      </Modal>
    </>
  )
}

const Media = ({ data: { galleria, categories } }) => {
  const classes = useStyles()

  return (
    <Layout title="Galleria">
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h1
                  style={{
                    color: "#fe5f55",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Galleria
                </h1>
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </Grid>
          </Container>
        </section>
        <Categorie categories={categories} prefix="galleria" />
        <section className={classes.galleria}>
          <Container>
            <Grid container>
              {galleria.nodes.map((image, index) => (
                <Grid item md={4} sm={6} xs={12} key={index} id={image.slug}>
                  <SimpleCard
                    title={image.titolo}
                    excerpt={image.descrizione.descrizione}
                    image={
                      image.media.fluid ? image.media.fluid.src : defaultImage
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default Media

export const query = graphql`
  query($categoria: String!) {
    galleria: allContentfulMedia(
      sort: { order: DESC, fields: updatedAt }
      filter: {
        titolo: { regex: "/^((?!Foto di|Stats|Rassegna|Manifesti).)*$/gm" }
        categorie: { elemMatch: { slug: { glob: $categoria } } }
        inGalleria: { eq: true }
        node_locale: { eq: "it-IT" }
      }
    ) {
      nodes {
        titolo
        slug
        descrizione {
          descrizione
        }
        media {
          fluid(quality: 100) {
            src
          }
        }
      }
    }
    categories: allContentfulCategoria(
      filter: { node_locale: { eq: "it-IT" } }
    ) {
      nodes {
        titolo
        slug
      }
    }
  }
`
